import { render, staticRenderFns } from "./SearchModel.vue?vue&type=template&id=477c2293&scoped=true&"
import script from "./SearchModel.vue?vue&type=script&lang=js&"
export * from "./SearchModel.vue?vue&type=script&lang=js&"
import style0 from "./SearchModel.vue?vue&type=style&index=0&id=477c2293&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "477c2293",
  null
  
)

export default component.exports